import React from "react";

const BgImage = () => {
    return (
        <div className="w-full h-[400px]">
            <img src="/images/keyword/bf.jpg" alt="image" className="w-full h-full object-fill" />
        </div>
    );
};

export default BgImage;